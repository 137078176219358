<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb title="Terapi" :array="[
        { islink: true, url: '/', text: 'Ana Sayfa' },
        { islink: false, url: '', text: 'Terapi' },
      ]" />
    </section>
    <!--/Breadcrumb-->

    <!--Categories-->

    <section class="sptb">
      <div class="container">
        <div class="item-all-cat center-block text-center books-categories" id="container2">
          <div class="row">
            <template v-if="serviceList.length == 0">
              <div class="col-xl-4 col-md-6 col-sm-6" v-for="(item, i) in 16" :key="'experiences-' + i">
                <div class="card border-0 overflow-hidden skeleton w-100 p-1" style="height: 180px"></div>
              </div>
            </template>
            <div class="col-xl-4 col-md-6 col-sm-6" v-for="(item, i) in serviceList" :key="i">
              <div class="card border-0 overflow-hidden">
                <div class="item-card">
                  <div class="item-card-desc">
                    <router-link :to="'experiences/' + item.short_name + '/detail'"></router-link>
                    <div class="item-card-img">
                      <lazy-image :src="'https://storage.terapivitrini.com/' + item.thumbnail
                        " alt="img" class="br-te-7 br-ts-7" />
                    </div>
                    <div class="item-card-text">
                      <h4 class="mb-0">
                        {{ item.experience_name }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/Categories-->
  </div>
</template>
<style>
.item-card-desc .item-card-text {
  opacity: 1;
  scale: 1;
  transition: all 1s ease;
}

.item-card-desc:hover .item-card-text {
  transition: all 1s ease;
  scale: 1.5;
  opacity: 1;
}
</style>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
export default {
  metaInfo() {
    return {
      title: "Terapiler ve tedaviler: trendlerde ara",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Türkiye’de en çok aranan terapiler ve tedaviler nelerdir nasıl yapılır ve onlarla alakalı en sık sorulan soruları bulun.",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;


  },
  data() {
    return {
      list: [],
      city: "",
      serviceList: [],
      perpage: 20,
      currentpage: 1,
      isLoad: true,
      loadfull: true,
    };
  },
  methods: {
    handleScroll() { },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    page() {
      let serviceInfo = {};
      this.$store.dispatch("serviceList", serviceInfo).then((value) => {
        this.serviceList = value;
        if (value == null) {
          this.loadfull = true;
        }
        this.isLoad = false;
        let _this = this;
        $(document).ready(function () {
          let content = document.getElementById("appcontent").innerHTML;
          _this.$store.dispatch("setMetaContent", [
            "Terapiler ve tedaviler: trendlerde ara",
            "Türkiye’de en çok aranan terapiler ve tedaviler nelerdir nasıl yapılır ve onlarla alakalı en sık sorulan soruları bulun.",
            "",
            "",
            "",
            content,
          ]);
        });
      });
    },
  },
  mounted() {
    this.backToTop();
    this.page();
  },
  components: { Breadcrumb },
};
</script>